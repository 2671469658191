import { AuthService } from "../core/auth.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { StoreService } from "../service/store.service";
import { UserService } from "../service/user.service";
import { ToastService } from "ng-uikit-pro-standard";
import { getAuth, RecaptchaVerifier } from "@angular/fire/auth";

interface IcampingUserModel {
  email: string;
  password: string;
}
declare global {
  interface Window {
    confirmationResult: any;
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  icampingUserModel = {} as IcampingUserModel;
  icampingUserModelResetPassword = {} as IcampingUserModel;
  newIcampingUserModel = {} as IcampingUserModel;
  showSpinner: boolean;
  showLoginWindow: boolean;
  showPhoneLoginWindow: boolean;
  phoneModel: string;
  recaptchaVerifier!: RecaptchaVerifier;

  constructor(
    private _storeService: StoreService,
    private _userService: UserService,
    private toastService: ToastService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this._storeService.hidePageLoading();
    const keepLoading = window.sessionStorage.getItem("keepLoading");

    if (keepLoading !== "true") {
      console.log("showSpinner is false");
      this.showSpinner = false;
    } else {
      console.log("showSpinner is true");

      this.showSpinner = true;
      setTimeout(() => {
        console.log("showSpinner for 5 sec.");
        this.showSpinner = false;
        window.sessionStorage.removeItem("keepLoading");
      }, 5000);
    }
    this.showLoginWindow = true;
    this.showPhoneLoginWindow = true;
    // Initialize reCAPTCHA verifier
    this.recaptchaVerifier = new RecaptchaVerifier(
      "signUpPhone-btn", // The container ID
      {
        size: "invisible",
        "data-sitekey": "6LfgyS4qAAAAAEMkdgOMCjx6LyliykafNJvq5Xow",
        callback: (response: any) => {
          console.log("reCAPTCHA solved, asking for verification code now.");
        },
        "expired-callback": () => {
          console.error("Recaptcha expired, please solve it again.");
        },
        "data-error-callback": (response: any) => {
          console.error("Recaptcha error happened.");
        },
      },
      this.auth.auth
    );

    this.recaptchaVerifier.render().then((widgetId) => {
      console.log("reCAPTCHA widget ID:", widgetId);
    });

    this.recaptchaVerifier.verify().then((token) => {
      console.log(token);
    });

    // listen for auth state changes
    this.auth;

    // listen for auth state changes
    this.auth.user$.subscribe((user) => {
      if (user && user.providerId === "phone") {
        console.log("calling new account api user for NON Phone user:", user);

        this._userService
          .UsersByPhoneNumber(user.uid)
          .subscribe((result: any) => {
            console.log(result);
            if (result.users.length === 0) {
              console.log("No old account found for this phone.");
              return;
            } else {
              // Save the user.uid as 'phone_firebase_uid'
              const phone_firebase_uid = encodeURIComponent(user.uid);

              // Filter users whose oauth_type is not 'phone'
              const nonPhoneUsers = result.users.filter(
                (user) => user.oauth_type !== "phone"
              );

              if (nonPhoneUsers.length === 0) {
                console.log("No non-phone users found.");
                return;
              }

              // Redirect to the accounts.icamping.app /noauth-redirect migration page
              let baseUrl = environment.accountsIcampingApp;
              // Add the phone_firebase_uid as part of the queryParams
              let queryParams =
                `phone_firebase_uid=${phone_firebase_uid}&` +
                nonPhoneUsers
                  .map((user, index) => {
                    const userFirebaseUidParam = `user_firebase_uid_${index}=${encodeURIComponent(
                      user.user_firebase_uid
                    )}`;
                    const oauthTypeParam = `oauth_type_${index}=${encodeURIComponent(
                      user.oauth_type
                    )}`;
                    const photoUrlParam = `photo_url_${index}=${encodeURIComponent(
                      user.photo_url || "https://avatar.iran.liara.run/public"
                    )}`;

                    return `${userFirebaseUidParam}&${oauthTypeParam}&${photoUrlParam}`;
                  })
                  .join("&");

              // Construct the full URL
              const redirectUrl = `${baseUrl}?${queryParams}`;
              console.log("Redirecting to:", redirectUrl);

              // set一個sessiionstorage 告訴 home component 有手機帳號可以做轉移
              // home component 會去檢查這個sessionstorage然後彈出 mdbModal問使用者要不要轉移
              window.sessionStorage.setItem(
                "has_phone_account_migration",
                "true"
              );
              window.sessionStorage.setItem(
                "phone_account_migration_redirect_url",
                redirectUrl
              );
            }
          });
      }
    });
  }

  signInWithPhone() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true");

    // Ensure the phoneModel starts with '+886'
    if (this.phoneModel.startsWith("0")) {
      this.phoneModel = this.phoneModel.substring(1); // Remove the leading '0'
    }
    this.phoneModel = `+886${this.phoneModel}`; // Add the '+886' prefix
    const phoneNumber = this.phoneModel; // Replace with dynamic phone number input
    const appVerifier = this.recaptchaVerifier;
    this.auth.phoneLogin(this.auth.auth, phoneNumber, appVerifier);
  }

  signInWithGoogle() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286
    this.auth.googleLogin();
  }

  signInWithFacebook() {
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286
    this.auth.facebookLogin();
  }

  signInWithIcamping(icampingUserModel) {
    console.log("Step1: 嘗試用該帳號密碼驗證舊愛露營使用者資料庫");
    this.showSpinner = true;
    window.sessionStorage.setItem("keepLoading", "true"); // /store/yssl286

    this.showLoginWindow = false;
    this.auth
      .emailLogin(icampingUserModel.email, icampingUserModel.password)
      .then((result: any) => {
        console.log(result);

        if (result.code === "auth/user-not-found") {
          console.log(
            "Step1: 該使用者沒有在新版愛露營建立過帳號，且與舊版愛露營帳號密碼驗證失敗！"
          );
          console.log("Step2: 為該使用者新增新版愛露營帳號！");
          // 新增該使用者到新版icamping, 建立好後會跑casebinding
          this.signUpWithIcamping(
            icampingUserModel.email,
            icampingUserModel.password
          );
        } else if (result.code === "auth/wrong-password") {
          console.error(
            "Step2.2: 該使用者在新版愛露營有帳號，但是Firebase密碼輸入錯誤！"
          );
          this.toastService.error("新版愛露營密碼錯誤");
          this.icampingUserModel.password = "";
          this.showSpinner = false;
          this.showLoginWindow = true;
          return false;
        } else {
          console.error(
            "Step2.2: 該使用者在新版愛露營有帳號，且Firebase密碼驗證，我們要把user導回到home！"
          );
          this.auth.updateUserData(result.user).then(() => {
            this.auth.icampingLocalStorageSet(
              result.user.email,
              result.user.uid
            );
            this.home();
          });
        }
      });
  }

  signUpWithIcamping(email: string, password: string) {
    if (email.includes("@gmail.com")) {
      return;
    }

    this.showSpinner = true;

    this.auth
      .emailSignUp(email, password)
      .then((authorized) => {
        if (authorized.user) {
          console.log("Step6: 註冊新版愛露營帳號成功!");
          console.log("Step7: 更新新版使用者資料到icamping-tree Firestore！");
          this.auth.updateUserData(authorized.user).then(() => {
            // console.log('Step8: 導到 /profile做手機驗證');
            console.log("Step8: 做case binding並導入home");
            this.auth.icampingLocalStorageSet(
              authorized.user.email,
              authorized.user.uid
            );
            this.afterSignIn();
          });
        } else {
          this.showSpinner = false;
          this.showLoginWindow = true;
        }
      })
      .catch((error) => {
        this.toastService.error(`愛入營帳號註冊失敗，請嘗試，謝謝`);
        console.log(error);
        this.showSpinner = false;
        this.showLoginWindow = true;
      });
  }

  resetPassword(icampingUserModelResetPassword) {
    this.auth
      .resetPassword(icampingUserModelResetPassword.email)
      .then(() => {});
  }

  signOut() {
    new Promise<void>((resolve, reject) => {
      try {
        this.auth.signOut();
        resolve(); // Resolve the promise once signOut is called
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    })
      .then(() => {
        // After the sign out process completes, reload the page
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
      });
  }

  private afterSignIn() {
    this.home();
    this.showSpinner = false;
  }

  private home() {
    window.location.href = `${environment.baseUrl}`;
  }
}
